//  background-color: ${(p) => (p.test2Color ? p.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};
//import { isDark } from "V3";

import { styled, css } from "V3/Components/styledMixins/styledMixins.js";

export const S = {
    Container: styled.div`
        ${({ isDarkMode, page, totalCount }) => css`
            background-color: ${isDarkMode ? "#333333" : "#999999"};
            width: 100%;
            margin-top: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            z-index: 99;
            padding: 20px 0px;
            gap: 10px;
            margin-bottom: 20px;

            & > div {
                width: 60px;
                height: 60px;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            & > #first {
                background: #251b80;
                pointer-events: ${page < 3 ? "none" : "auto"};
                opacity: ${page < 3 ? 0 : 1};
                transform: scale(0.8);
            }

            & > #previous {
                background: #321fdb;
                pointer-events: ${page < 2 ? "none" : "auto"};
                opacity: ${page < 2 ? 0 : 1};
            }
            & > #page {
                flex: 1 1 auto;
                font-size: 12px;
            }
            & > #next {
                background: #321fdb;
                pointer-events: ${page > totalCount - 1 ? "none" : "auto"};
                opacity: ${page > totalCount - 1 ? 0 : 1};
            }
            & > #last {
                background: #251b80;
                pointer-events: ${page > totalCount - 2 ? "none" : "auto"};
                opacity: ${page > totalCount - 2 ? 0 : 1};
                transform: scale(0.8);
            }
        `}
    `,
};
