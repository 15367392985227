/** @jsxImportSource @emotion/react */
import CircularProgress from "@material-ui/core/CircularProgress";

const cssRoot = {
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    gap: 50,
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.6)",
    top: 0,
    left: 0,
    zIndex: 10000,
    backdropFilter: "blur(5px)",
};

export default function OverlayCircularLoader({ msg = undefined }) {
    return (
        <div css={cssRoot} aria-label="OverlayCircularLoader">
            <CircularProgress />
            {msg && <h6>{msg}</h6>}
        </div>
    );
}
