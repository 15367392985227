//  background-color: ${(props) => (props.test2Color ? props.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.phone(css` background-color: green; `)}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

// import { isDark } from "V3";
import { styled, css, g } from "V3/Components/styledMixins/styledMixins.js";

export const Styled = {
    Container: styled.div`
        min-width: 100vw;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #3b4b63;
        color: white;
        user-select: none;
    `,

    SvgShadow2: styled.svg`
        position: fixed;
        top: 0;
        left: 0px;
        width: 100vw;
        height: 52vh;
        filter: blur(10px);
        fill: rgba(0, 0, 0, 0.7);
        z-index: 1;
    `,
    Triangle2: styled.div`
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 50%, 100% 0);
        background: linear-gradient(223deg, #415677 0%, #3b4b63 100%);
        z-index: 2;
    `,
    //
    SvgShadow1: styled.svg`
        position: fixed;
        top: 0;
        left: 3px;
        width: 50vw;
        height: 102vh;
        filter: blur(10px);
        fill: rgba(0, 0, 0, 0.7);
        z-index: 3;

        ${g.media(
            "phone,tablet",
            css`
                width: 90vw;
            `,
        )}
    `,
    Triangle1: styled.div`
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        clip-path: polygon(0 0, 50% 100%, 0 100%);
        background: linear-gradient(0deg, #3b4b63 0%, #415677 200%);
        z-index: 4;

        ${g.media(
            "phone,tablet",
            css`
                clip-path: polygon(0 0, 100% 100%, 0 100%);
            `,
        )}
    `,
    ContentArea: styled.div`
        width: max-content;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 2px;
        animation: slideDown 1s ease-out forwards;

        @keyframes slideDown {
            from {
                margin-top: 13%;
                opacity: 0;
            }
            to {
                margin-top: 10%;
                opacity: 1;
            }
        }

        ${g.media(
            "phone,tablet",
            css`
                animation: slideDownMobile 1s ease-out forwards;

                @keyframes slideDownMobile {
                    from {
                        margin-top: 15%;
                        opacity: 0;
                    }
                    to {
                        margin-top: 0%;
                        opacity: 1;
                    }
                }
            `,
        )}
    `,
    LogoArea: styled.div`
        width: max-content;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 2px;

        & > div:nth-child(1) {
            width: 100%;
            text-align: left;
            font-size: 18px;
            font-weight: 100;
        }
        & > div:nth-child(2) {
            width: 200px;
        }
    `,
    GoogleLoginArea: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        padding: 50px;
        cursor: pointer;

        & > div:nth-child(1) {
            position: absolute;
            opacity: 0;
        }
        & > div:nth-child(2) {
            pointer-events: none;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                bottom: -4px;
                height: 2px;
                width: 0%;
                left: 50%;
                background: rgb(12, 142, 218);
                transition: all 250ms ease-in-out;
            }
        }

        &:hover {
            & > div:nth-child(2):before {
                width: 100%;
                left: 0;
            }
        }
    `,
};
