import { useContext, AppContext, Icon, IconLib } from "V3";
import { S } from "./_styled";

export const MobileFooter = ({ onSubmit, onCancel, disableSubmit, disableCancel, hideCancel, hideSubmit }) => {
    const { isDarkMode, isMobile } = useContext(AppContext);

    /* Return */
    if (!isMobile) return null;
    return (
        <S.Container isDarkMode={isDarkMode} disableSubmit={disableSubmit} disableCancel={disableCancel}>
            {!hideCancel && (
                <div id="left" onClick={!disableCancel && onCancel}>
                    <Icon icon={IconLib.IconClose} width={18} color="white" />
                </div>
            )}
            {!hideSubmit && (
                <div id="right" onClick={!disableSubmit && onSubmit}>
                    <Icon icon={IconLib.IconCheck} width={22} color="white" />
                </div>
            )}
        </S.Container>
    );
};
