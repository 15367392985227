import { Styled } from "./_styled.js";
import logo from "./logo.svg";

const Suspence = ({ children }) => (
    <Styled.Container>
        <Styled.SvgShadow1 viewBox="0 0 100 100" preserveAspectRatio="none">
            <path d="M0 0 L100 100 L0 100 Z" />
        </Styled.SvgShadow1>
        <Styled.Triangle1 />
        <Styled.SvgShadow2 viewBox="0 0 100 100" preserveAspectRatio="none">
            <path d="M100 0 L0 100 L100 100 Z" />
        </Styled.SvgShadow2>
        <Styled.Triangle2 />
        <Styled.ContentArea>
            <Styled.LogoArea>
                <div>Welcome to</div>
                <div>
                    <img src={logo} alt="logo" />
                </div>
            </Styled.LogoArea>
            {children || "Suspence"}
        </Styled.ContentArea>
    </Styled.Container>
);

export default Suspence;
