//  background-color: ${(p) => (p.test2Color ? p.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};
//import { isDark } from "V3";

import { styled, css } from "V3/Components/styledMixins/styledMixins.js";

export const S = {
    Container: styled.div`
        width: 100%;
        display: flex;
        gap: 10px;
        margin-bottom: 20px;
        padding: ${(p) => (p.enablePadding ? "10px" : "0")};
    `,
    SingleButton: styled.div`
        width: 80px;
        height: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        line-height: 1.1;
        border-radius: 10px;
        padding: 5px;

        & > #iconArea {
            height: 20px;
        }
        & > #label {
            font-size: 11px;
            text-align: center;
        }

        ${({ isDarkMode }) =>
            isDarkMode
                ? css`
                      background: rgba(255, 255, 255, 0.1);
                  `
                : css`
                      background: rgba(0, 0, 0, 0.1);
                  `}
    `,
};
