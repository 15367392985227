import { Container } from "./TextSeperator.styled.js";
import { useContext, AppContext } from "V3";

export const TextSeperator = (props) => {
    const { isDarkMode, isMobile } = useContext(AppContext);
    const { t = "", style, onClick, block, marginTop } = props || {};

    /**
     * Return
     */
    if (isMobile) return null;
    return <Container {...{ style, onClick, block, marginTop, isDarkMode }}>{t}</Container>;
};
