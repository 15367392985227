import { useContext, AppContext, Icon, IconLib } from "V3";
import { S } from "./_styled";

export const MobilePagination = ({ page, totalCount, onChange }) => {
    const { isDarkMode, isMobile } = useContext(AppContext);

    const goFirst = () => onChange(1);
    const goPrevious = () => onChange(page - 1);
    const goNext = () => onChange(page + 1);
    const goLast = () => onChange(totalCount);

    /* Return */
    if (!isMobile || 1 === totalCount) return null;
    return (
        <S.Container isDarkMode={isDarkMode} page={page} totalCount={totalCount}>
            <div id="first" onClick={goFirst}>
                <Icon icon={IconLib.First} width={18} color="white" />
            </div>
            <div id="previous" onClick={goPrevious}>
                <Icon icon={IconLib.Previous} width={18} color="white" />
            </div>
            <div id="page">
                {page} / {totalCount}
            </div>
            <div id="next" onClick={goNext}>
                <Icon icon={IconLib.Next} width={18} color="white" />
            </div>
            <div id="last" onClick={goLast}>
                <Icon icon={IconLib.Last} width={18} color="white" />
            </div>
        </S.Container>
    );
};
