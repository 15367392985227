function isDashBoxLink(url) {
    if (typeof url !== "string") return false;

    const forbiddenStart = "https://shrn.care/";
    const forbiddenPatterns = [/\/box_/, /\/\/bs_/, /\/b_/];

    if (url.startsWith(forbiddenStart)) {
        return true;
    }

    for (const pattern of forbiddenPatterns) {
        if (pattern.test(url)) {
            return true;
        }
    }

    return false;
}

function isMyAffiliateLink(url) {
    if (typeof url !== "string") return false;

    const forbiddenPatterns = [/\/record\./, /\.care/];

    for (const pattern of forbiddenPatterns) {
        if (pattern.test(url)) {
            return true;
        }
    }

    return false;
}

export function isDashBoxOrMyAffiliateLink(url) {
    if (typeof url !== "string") return false;
    return isDashBoxLink(url) || isMyAffiliateLink(url);
}
