import React, { useContext } from "react";
import { Icon, IconLib } from "V3";
import AppContext from "../store/AppContext";

const EditIcon = (props) => {
    const { state, isDarkMode } = useContext(AppContext);
    const { isBlank, noClick } = props || {};

    const editAction = () => {
        let editUrl = props.url + props.data;

        if (parseInt(state?.userSettings?.edit_tab) === 1 || isBlank) {
            window.open(editUrl, "_blank");
        } else {
            window.location.href = editUrl;
        }
    };

    return (
        < Icon
            style={{ color: "darkgray", cursor: "pointer", marginRight: "7px" }}
            icon={IconLib.IconEdit}
            color={isDarkMode ? "white" : "grey"}
            onClick={() => {
                if (!noClick) editAction();
            }}
            width={20}
            height={20}
        />
    );
};

export default EditIcon;
