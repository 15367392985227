/*
routes = {
    sidebarSectionName = [
        [
            [
                pageName,           // mandatory
                path,               // mandatory
                component,          // mandatory
            ],
            {
                p: [                // optional permission settings
                                    // admin can see all pages
                    p1,             // the pages which are allowed for defaultUsers
                                            (defaultUser means, the user is not admin, not seo, not publisher)
                    p2,             // the pages which are allowed for publishers
                    p3,             // the pages which are allowed for seoUsers
                    p4,             // the pages which are allowed for costUsers
                    p5,             // starter profile can see only marketplace
                    p6,             // the pages which are allowed for operations
                ],
                m:[                 // optional menu settings
                    m1,             // the pages which are visible under viewMenu
                    m2              // the pages which are visible under addMenu
                ],
                b: {                // optional behaviour settings
                    hide: true      // hide page at menus.
                },
                pr: {               // optional route prop settings.
                                    // these data can be reached via page components.
                },
            },
        ]
    ],
}
*/

import { _, lazy } from "V3";

const AccountsList = lazy(() => import("V3/Pages/Admin/Accounts/List/AccountsList.js")),
    AccountsForm = lazy(() => import("V3/Pages/Admin/Accounts/Form/AccountsForm.js")),
    SeoCampaignBoxForm = lazy(() => import("./views/seo/SeoCampaignBoxForm/SeoCampaignBoxForm.js")),
    ViewIndividualSeoBox = lazy(() => import("./views/seo/ViewIndividualSeoBox")),
    AffiliateNetworksForm = lazy(() => import("./views/AffiliateNetworks/Form/AffiliateNetworkForm.js")),
    AffiliateNetworksList = lazy(() => import("./views/AffiliateNetworks/List/AffiliateNetworksList.js")),
    MarketplaceApprovalPage = lazy(() => import("./views/MarketplaceApproval/MarketplaceApprovalPage.js")),
    AffiliateTransactionReport = lazy(() =>
        import("./views/Reports/AffiliateTransactionReport/AffiliateTransactionReport.js"),
    ),
    CampaignBoxForm = lazy(() => import("./views/CampaignBox/CampaignBoxFormV2")),
    CampaignBoxList = lazy(() => import("./views/CampaignBox/List/CampaignBoxList.js")),
    ViewIndividualBox = lazy(() => import("./views/CampaignBox/ViewIndividualBox")),
    LinksChecker = lazy(() => import("V3/Pages/LinksChecker/LinksChecker.js")),
    LinksCheckerDownload = lazy(() => import("V3/Pages/LinksChecker/_download.js")),
    CampaignEditForm = lazy(() => import("./views/Campaigns/CampaignEditForm/CampaignEditForm")),
    CampaignForm = lazy(() => import("./views/Campaigns/CampaignForm")),
    CampaignList = lazy(() => import("./views/Campaigns/List/CampaignList")),
    CampaignStats = lazy(() => import("./views/Reports/campaign/CampaignStatsWithAgGrid")),
    ClientList = lazy(() => import("./views/Clients/ClientList")),
    ClientForm = lazy(() => import("./views/Clients/ClientForm")),
    WPClientForm = lazy(() => import("./views/Clients/WPClientForm")),
    WPClientView = lazy(() => import("./views/Clients/WPClientView")),
    PublisherForm = lazy(() => import("./views/publisher/PublisherForm/PublisherForm.js")),
    DefaultIntO = lazy(() => import("./views/DefaultIntegrationOverride/DefaultIntegrationOverridePage.js")),
    MultiDomainForm = lazy(() => import("./views/Domains/Multiple Domain Creation/MultiDomainForm.js")),
    DomainList = lazy(() => import("./views/Domains/DomainList/DomainList")),
    DashOfferForm = lazy(() => import("V3/Pages/CampaignBoxes/Offers/EditOffer/DashOfferForm.js")),
    PublisherOfferForm = lazy(() => import("V3/Pages/CampaignBoxes/Offers/EditOffer/PubOfferForm.js")),
    EmailPublisherForm = lazy(() => import("./views/publisher/EmailPublisherForm")),
    EventReporter = lazy(() => import("./views/Reports/EventReporter/EventReporter.js")),
    EventReporterClickIdView = lazy(() => import("./views/Reports/EventReporter/EventReporterClickIdView.js")),
    FakeFtdForm = lazy(() => import("./views/Reports/FakeFtdForm/FakeFtdForm.js")),
    FailedClicks = lazy(() => import("./views/Reports/FailedClicks/FailedClicks.js")),
    FTDApprovalForm = lazy(() => import("./views/FTDApproval/FTDApprovalForm")),
    FTDApprovedPage = lazy(() => import("./views/FTDApproval/FTDApprovedPage.js")),
    GeoRevenuesAddForm = lazy(() => import("./views/Partners/GeoRevenuesAddForm")),
    GeoRevenuesPage = lazy(() => import("./views/Partners/GeoRevenues/GeoRevenuePage.js")),
    GlobalDealForm = lazy(() => import("V3/Pages/Admin/GlobalDealForm/GlobalDealForm.js")),
    StatusMappingOverride = lazy(() => import("V3/Pages/Admin/StatusMappingOverride/StatusMappingOvverridePage.js")),
    GlobalRules = lazy(() => import("V3/Pages/Admin/GlobalRules/GlobalRules.js")),
    // HomeV2 = lazy(() => import("V3/Pages/HomeV2/HomeV2.js")),
    IntegrationList = lazy(() => import("./views/Partners/PartnersList/PartnersList.js")),
    PartnersForm = lazy(() => import("./views/Partners/PartnersForm")),
    LeadSearchReport = lazy(() => import("./views/Reports/LeadSearch/LeadSearchReport.js")),
    // LeadStatusReport = lazy(() => import("./views/Reports/LeadStatusReport")),
    LeadWaterfallManage = lazy(() => import("./views/Leads/waterfall/LeadWaterfallManage")),
    LeadmailerForm = lazy(() => import("./views/Leadmailer/LeadmailerForm/LeadmailerForm")),
    LeadmailerList = lazy(() => import("./views/Leadmailer/LeadmailerList/LeadmailerList")),
    LeadmailerJobView = lazy(() => import("./views/Leadmailer/LeadmailerJobView/LeadmailerJobView")),
    Logout = lazy(() => import("./views/pages/Logout/Logout")),
    ManualTransactionAdd = lazy(() => import("V3/Pages/ManualTransaction/Add/MTAdd.js")),
    ManualTransactionList = lazy(() => import("V3/Pages/ManualTransaction/List/MTList.js")),
    Marketplace = lazy(() => import("V3/Pages/Marketplace/Marketplace.js")),
    SimpleCostForm = lazy(() => import("V3/Pages/ManualTransaction/Simple/SimpleCostForm.js")),
    // SeoCostForm = lazy(() => import("V3/Pages/ManualTransaction/SeoCost/SeoCostForm.js")),
    // SeoCostList = lazy(() => import("V3/Pages/ManualTransaction/SeoCostList/SeoCostList.js")),
    // SeoCostUpload = lazy(() => import("V3/Pages/ManualTransaction/SeoCostsUpload/SCUpload.js")),
    GamblingStatsForm = lazy(() => import("V3/Pages/GamblingStats/Add/GSAdd.js")),
    GamblingStatsList = lazy(() => import("V3/Pages/GamblingStats/List/GSList.js")),
    GamblingStatsUpload = lazy(() => import("V3/Pages/GamblingStats/Upload/GSUpload.js")),
    NWidgetForm = lazy(() => import("V3/Pages/Widgets/Form/Form.js")),
    NWidgetsList = lazy(() => import("V3/Pages/Widgets/List/List.js")),
    OfferForm = lazy(() => import("./views/Offers/OffersForm.js")),
    OfferList = lazy(() => import("./views/Offers/OffersList.jsx")),
    PartnerStatistics = lazy(() => import("./views/Reports/PartnerStatistics/PartnerStatistics.js")),
    PrelanderDomainsList = lazy(() => import("./views/PrelanderDomains/List/PrelanderDomainsList.js")),
    PrelandersForm = lazy(() => import("./views/Prelanders/PrelandersForm")),
    Defaults = lazy(() => import("./views/Prelanders/Defaults/Defaults")),
    PrelandersList = lazy(() => import("./views/Prelanders/PrelandersList")),
    Profile = lazy(() => import("./views/users/Profile")),
    PubLeadQRep = lazy(() => import("./views/publisher/PublisherLeadQueryReport/PublisherLeadQueryReport.js")),
    RedirectDomainList = lazy(() => import("./views/Domains/RedirectDomainList")),
    RulesForm = lazy(() => import("./views/Rules/RulesForm/RulesForm")),
    RulesList = lazy(() => import("./views/Rules/RulesList/RulesList")),
    SeoStatsV3 = lazy(() => import("V3/Pages/Reports/SeoStatsV3/SeoStatsV3.js")),
    AddBlockedIPAddress = lazy(() => import("./views/blockedIp/AddBlockedIpAddress")),
    ShowBlockedIpAddressList = lazy(() => import("./views/blockedIp/ShowBlockedIpAddressList.js")),
    BlockListTable = lazy(() => import("./views/BlockListManager/BlockListTable/BlockListTable.js")),
    BlockListForm = lazy(() => import("./views/BlockListManager/BlockListForm/BlockListForm.js")),
    ShowOffersPage = lazy(() => import("./views/Offers/ShowOffers/Offers.js")),
    PublishersList = lazy(() => import("./views/publisher/PublisherList/PublishersList.js")),
    SeoCampaignBoxList = lazy(() => import("./views/seo/List/SeoCampaignBoxList.js")),
    TagManagement = lazy(() => import("V3/Pages/Admin/TagManagement/TagManagement.js")),
    WebsiteForm = lazy(() => import("./views/Websites/Form")),
    WebsitesList = lazy(() => import("./views/Websites/List/WebsitesList")),
    UnassignedStatusForm = lazy(() => import("V3/Pages/Admin/UnassignedStatusForm/UnassignedStatusForm.js")),
    UserV2 = lazy(() => import("V3/Pages/Admin/UserV2/UserV2.js")),
    ManualUserCreateForm = lazy(() => import("V3/Pages/Admin/UserV2/ManualUserCreateForm.js")),
    Waterfall2 = lazy(() => import("./views/Partners/Waterfall2/Waterfall2/Waterfall2.js")),
    WaterfallBadIp = lazy(() => import("./views/Partners/WaterfallBadIp")),
    Whitelist = lazy(() => import("./views/whitelist/Whitelist")),
    BannerGlobalRulesForm = lazy(() => import("V3/Pages/Admin/BannerGlobalRules/Form/BannerGlobalRulesForm.js")),
    BannerGlobalRulesList = lazy(() => import("V3/Pages/Admin/BannerGlobalRules/List/BannerGlobalRulesList.js")),
    DirectLinksForm = lazy(() => import("./views/DirectLinks/DirectLinksForm.js")),
    AdvancedReportingList = lazy(() => import("V3/Pages/AdvancedReporting/List/List")),
    AdvancedReportingEdit = lazy(() => import("V3/Pages/AdvancedReporting/Edit/Edit")),
    AdvRepDownload = lazy(() => import("V3/Pages/AdvancedReporting/Download/Download")),
    Referrers = lazy(() => import("./V3/Pages/Referrers/Referrers.js")),
    LifetimeReport = lazy(() => import("V3/Pages/Reports/LifetimeReport/LifetimeReport.js")),
    TableauTrendsByPartnerType = lazy(() => import("./views/Tableau/TableauTrendsByPartnerType.js")),
    PushNotificationsList = lazy(() => import("V3/Pages/PushNotifications/List/PushNotificationsList.js")),
    PushNotificationsForm = lazy(() => import("V3/Pages/PushNotifications/Form/PushNotificationsForm.js")),
    MegaBlockMirrors = lazy(() => import("./V3/Pages/MegaBlockMirrors/MegaBlockMirrors.js")),
    PartnerLinkChecker = lazy(() => import("./views/PartnerLinkChecker/PartnerLinkChecker.js"));

/**
 * Abbreviations
 */
export const defaultUserTitle = "Default User";
export const perms = [
    "Admin",
    defaultUserTitle,
    "Publisher",
    "Seo User",
    "Cost User",
    "Exclude All Pages",
    "Starter Profile",
    "Operations",
];
const menus = ["view", "add"];
const [p1, p2, p3, p4, p5, p6, m1, m2, hide] = [
    perms[1],
    perms[2],
    perms[3],
    perms[4],
    perms[6],
    perms[7],
    menus[0],
    menus[1],
    { hide: true },
];
//const cryptoPr = { category: true, categoryName: "Crypto PR" };
const gamblingPr = { category: true, categoryName: "Gambling" };

/**
 * !!! Warning !!!
 * PLEASE DON'T CHANGE page PATHS.
 * The "Users" page and permission sys uses paths for included / excluded pages.
 * If you need to change any, let Ian know and change settings at the BE.
 * api/user/{id} / DAS-1971
 */

export const routes = {
    hiddenFromSidebar: [
        /*[["HomeV2", "/homeV2", HomeV2], { p: [p1, p2, p3, p6] }],*/
        [["Home", "/", SeoStatsV3], { p: [p1, p2, p3, p6] }],
        [["Logout", "/logout", Logout], { p: [p1, p2, p3] }],
        [["User Profile", "/profile", Profile], { p: [p1, p2, p3] }],
    ],
    Reports: [
        [["Campaign Stats", "/campaign-stats", CampaignStats], { p: [p1, p2], m: [m1] }],
        [["Seo View Stats", "/seo-view", SeoStatsV3], { p: [p1, p2, p3, p4], m: [m1] }],
        /*[["Block Labs View", "/blockLabs-view", SeoStatsV3], { p: [p1, p2, p3], pr: cryptoPr }],*/
        [["Gambling View", "/gambling-view", SeoStatsV3], { p: [p1, p2, p3], m: [m1], pr: gamblingPr }],
        [["Parasites View", "/parasites-view", SeoStatsV3], { p: [p1, p2], m: [m1], pr: { parasites: true } }],
        [["Lifetime Report", "/lifetime-view", LifetimeReport], { p: [p1, p2], m: [m1] }],
        /*[["Banner Box View", "/banner-box-view", SeoStatsV3], { p: [p1, p2], m: [m1], pr: { bannerBox: true } }],*/
        [["Banner Box View", "/banner-box-view", SeoStatsV3], { p: [p1, p2], m: [m1], pr: { bannerBox: true } }],
        /*[["Wagering View", "/wagering-view", SeoStatsV3], { p: [p1, p2], m: [m1], pr: { wagering: true } }],*/
        [["Advanced Reporting List", "/advanced-reporting", AdvancedReportingList], { p: [p1], m: [m1] }],
        [["Edit Advanced Report", "/advanced-reporting/edit/:id", AdvancedReportingEdit], { p: [p1], b: hide }],
        [
            ["Duplicate Advanced Report", "/advanced-reporting/duplicate/:duplicateId", AdvancedReportingEdit],
            { p: [p1], b: hide },
        ],
        [["Add Advanced Report", "/advanced-reporting/add", AdvancedReportingEdit], { p: [p1], b: hide }],
        [
            ["Download Advanced Report", "/advanced-reporting/download/:downloadId", AdvRepDownload],
            { p: [p1], b: hide },
        ],
        [["Event Reporter", "/event-reporter", EventReporter], { p: [p1] }],
        [
            ["Event Reporter", "/event-reporter/support/:type/search/:id", EventReporterClickIdView],
            { p: [p1], b: hide },
        ],
        [["Failed Clicks", "/failed-clicks", FailedClicks]],
        [["Partner Statistics", "/partner-statistics", PartnerStatistics], { p: [p1, p2] }],
        [["Lead Search", "/lead-search", LeadSearchReport], { p: [p1] }],
        /*[["Charts", "/charts", HomeV2], { p: [p1, p2, p3] }],*/
    ],
    Temporary_Reports: [
        [["TempRepParam", "/campaign-stats/:preset", CampaignStats], { p: [p1, p2], b: hide, pr: { testing: "asd" } }],
        // [["Fightout by Website", "/campaign-stats/fightOut", CampaignStats], { p: [p1, p2], m: [m1] }],
    ],
    Marketplace: [
        [["Marketplace", "/marketplace", Marketplace], { p: [p1, p2, p3, p4, p5, p6], pr: { publishers: true } }],
    ],
    Click_Out: [
        [["Offers", "/show-offers", ShowOffersPage], { p: [p1, p2, p6] }],
        [["ClickOut Stats", "/publishers-view", SeoStatsV3], { p: [p1, p2], m: [m1], pr: { publishers: true } }],
        [["Affiliate Networks", "/affiliate-networks", AffiliateNetworksList], { p: [p6] }],
        [["Add Affiliate Network", "/affiliate-networks/add", AffiliateNetworksForm], { p: [p6] }],
        [["Edit AffNetwork", "/affiliate-networks/edit/:id", AffiliateNetworksForm], { p: [p6], b: hide }],
        [["MegaBlock Mirrors", "/megablock-mirrors", MegaBlockMirrors]],
        [["Publishers", "/publisher-list", PublishersList], { p: [p1, p2, p6] }],
        [["Add Publisher", "/publisher/add", PublisherForm], { p: [p1, p2, p6] }],
        [["Edit Publisher", "/publisher/edit/:id", PublisherForm], { p: [p1, p2, p6], b: hide }],
        [["Referrers", "/referrers", Referrers], { p: [p1, p6] }],
        [["Email Publishers Form", "/email-publisher", EmailPublisherForm]],
        [["Publisher Lead Query Report", "/publisher-lead-query-report", PubLeadQRep], { p: [p1] }],
        [["Deal Approval Form", "/deal-approval-form", FTDApprovalForm], { p: [p1, p2, p6] }],
        [["View Approved Deals", "/approved-deals", FTDApprovedPage], { p: [p1, p2, p6] }],
        [["Marketplace Approvals", "/approval-page", MarketplaceApprovalPage], { p: [p1, p2, p6] }],
        [["Affiliate Transaction Report", "/affiliate-transactions", AffiliateTransactionReport], { p: [p1, p2] }],
    ],
    Seo_Campaign_Boxes: [
        [["Seo Campaign Boxes", "/seo-campaign-box", SeoCampaignBoxList], { p: [p1, p2, p3, p4, p6] }],
        [["Add Seo Box", "/seo-campaign-box/add", SeoCampaignBoxForm], { p: [p1, p2, p3, p4, p6], m: [m2] }],
        [["Edit Seo Box", "/seo-campaign-box/edit/:id", SeoCampaignBoxForm], { p: [p1, p2, p3, p4, p6], b: hide }],
        [["Copy Seo Box", "/seo-campaign-box/copy/:id", SeoCampaignBoxForm], { p: [p1, p2, p3, p4, p6], b: hide }],
        [["View Seo Box", "/seo-campaign-box/view/:id", ViewIndividualSeoBox], { p: [p1, p2, p3, p4, p6], b: hide }],
    ],
    Campaign_Boxes: [
        [["Campaign Boxes", "/campaign-box", CampaignBoxList], { p: [p1, p2, p6] }],
        [["Add Campaign Box", "/campaign-box/add", CampaignBoxForm], { p: [p1, p2, p6], m: [m2] }],
        [["Edit Campaign Box", "/campaign-box/edit/:id", CampaignBoxForm], { p: [p1, p2, p6], b: hide }],
        [["Copy Campaign Box", "/campaign-box/copy/:id", CampaignBoxForm], { p: [p1, p2, p6], b: hide }],
        [["Edit Dashfx Offer", "/offers/dashfx/edit/:offerId", DashOfferForm], { p: [p1, p2, p6], b: hide }],
        [["Add Dashfx Offer", "/campaign-box/:boxId/offers/dashfx/add", DashOfferForm], { p: [p1, p2, p6], b: hide }],
        [["Edit Publisher Offer", "/offers/publisher/edit/:offerId", PublisherOfferForm], { p: [p1, p2, p6], b: hide }],
        [
            ["Add Publisher Offer", "/campaign-box/:boxId/offers/publisher/add", PublisherOfferForm],
            { p: [p1, p2, p6], b: hide },
        ],
        [["View Campaign Box", "/campaign-box/:id", ViewIndividualBox], { p: [p1, p2, p6], b: hide }],
        [["Links Checker", "/links-checker", LinksChecker], { p: [p1, p2, p3, p4] }],
        [
            ["Link Checker", "/links-checker/download/:downloadId", LinksCheckerDownload],
            { p: [p1, p2, p3, p4], b: hide },
        ],
        [
            ["Links Checker Form", "/links-checker-form", LinksChecker],
            { p: [p1, p2, p3, p4], m: [m1], b: hide, pr: { mode: "form" } },
        ],
        [
            ["Links Checker CSV Upload", "/links-checker-upload", LinksChecker],
            { p: [p1, p2, p3, p4], m: [m1], b: hide, pr: { mode: "upload" } },
        ],
        [
            ["Links Checker CSV Upload", "/links-checker-upload-email", LinksChecker],
            { p: [p1, p2, p3, p4], m: [m1], b: hide, pr: { mode: "email" } },
        ],
    ],
    Direct_Links: [[["Add Direct Link", "/direct-link/add", DirectLinksForm], { p: [p1, p2, p3, p4, p6], m: [m2] }]],
    Push_Notifications: [
        [["Push Notifications", "/push-notification", PushNotificationsList]],
        [["Add Push Notification", "/push-notification/add", PushNotificationsForm]],
        [["Edit Push Notification", "/push-notification/edit/:id", PushNotificationsForm], { b: hide }],
    ],
    Banners: [
        [["Banner Templates", "/templates", PrelandersList], { p: [p1, p6] }],
        [["Add Banner Template", "/templates/add", PrelandersForm], { p: [p1, p6], m: [m2] }],
        [["Edit Banner Template", "/templates/edit/:id", PrelandersForm], { p: [p1, p6], b: hide }],
        [["Edit Defaults", "/templates/defaults/:id", Defaults], { p: [p1, p6], b: hide }],
        [["Edit Defaults", "/templates/defaults/:id/:bannerId", Defaults], { p: [p1, p6], b: hide }],
        [["Banners", "/banners", NWidgetsList], { p: [p1, p6] }],
        [["Add Banner", "/banners/add", NWidgetForm], { p: [p1, p6], pr: { add: true } }],
        [["Edit Banner", "/banners/edit/:id", NWidgetForm], { p: [p1, p6], b: hide, pr: { edit: true } }],
        [["New Banner", "/banners/duplicate/:duplicateId", NWidgetForm], { p: [p1, p6], b: hide, pr: { edit: true } }],
    ],
    Rules: [
        [["Rules", "/rules", RulesList], { p: [p1, p6] }],
        [["Add Rule", "/rules/add", RulesForm], { p: [p1, p6], m: [m2] }],
        [["Edit Rules", "/rules/edit/:id", RulesForm], { p: [p1, p6], b: hide }],
        [["Copy Rules", "/rules/copy/:id", RulesForm], { p: [p1, p6], b: hide }],
        [["View Rules", "/rules/view/:id", RulesForm], { p: [p1, p6], b: hide }],
    ],
    Campaigns: [
        [["Campaigns", "/campaign-list", CampaignList], { p: [p1, p6] }],
        [["Add Campaign", "/campaign-form/add", CampaignForm], { p: [p1, p6], m: [m2] }],
        [["Add Affiliate Campaign", "/affiliate-campaign-form/add", CampaignEditForm], { p: [p1, p6], m: [m2] }],
        [["Edit Campaign", "/campaign/edit/:id", CampaignEditForm], { b: hide }],
        [["Partner Link Checker", "/partner-link-checker", PartnerLinkChecker]],
    ],
    Clients: [
        [["Clients", "/clients", ClientList]],
        [["Add WP Client", "/clients/add", WPClientForm]],
        [["View Client", "/clients/view/:id", WPClientView], { b: hide }],
        [["Edit Client", "/clients/edit/:id", ClientForm], { b: hide }],
    ],
    Partners: [
        [["Partners", "/partners", IntegrationList], { p: [p1] }],
        [["Add Partner", "/partners/add", PartnersForm], { p: [p1] }],
        [["Edit Partner", "/partners/edit/:id", PartnersForm], { p: [p1], b: hide }],
        [["Geo Revenues", "/partners/revenues", GeoRevenuesPage], { p: [p1, p6] }],
        [["Add Geo Revenues", "/partners/revenues/add", GeoRevenuesAddForm], { p: [p1, p6] }],
        [["Waterfall", "/partners/waterfall", Waterfall2], { p: [p1] }],
    ],
    Pixels: [
        [["Pixels", "/pixels", OfferList], { p: [p1] }],
        [["Add Pixels", "/pixels/add", OfferForm], { p: [p1] }],
        [["Edit Pixels", "/pixels/edit/:id", OfferForm], { p: [p1], b: hide }],
    ],
    Domains: [
        [["Domains", "/domains", DomainList], { p: [p1, p6] }],
        [["Add Domains", "/domains/add", MultiDomainForm], { p: [p1, p6] }],
        [["Edit Domain", "/domains/edit/:id", MultiDomainForm], { p: [p1, p6], b: hide }],
    ],
    Websites: [
        [["Websites", "/websites", WebsitesList], { p: [p1, p6] }],
        [["Add Website", "/websites/add", WebsiteForm], { p: [p1, p6] }],
        [["Edit Website", "/websites/edit/:id", WebsiteForm], { p: [p1, p6], b: hide }],
    ],
    Leads: [[["Waterfall", "/lead-waterfall-manage", LeadWaterfallManage], { p: [p1] }]],
    Leadmailer: [
        [["Add Leadmailer", "/leadmailers/add", LeadmailerForm]],
        [["Edit Leadmailer", "/leadmailers/edit/:id", LeadmailerForm], { b: hide }],
        [["View Leadmailers", "/leadmailers", LeadmailerList], { p: [p1] }],
        [["View Leadmailer", "/leadmailers/:id", LeadmailerJobView], { p: [p1], b: hide }],
    ],
    Blocked: [
        [["Blocked IPs", "/blocked-ip-list", ShowBlockedIpAddressList], { p: [p1] }],
        [["Add Blocked IP", "/blocked-ip/add", AddBlockedIPAddress], { p: [p1] }],
        [["Edit Blocked IP", "/blocked-ip/edit/:id", AddBlockedIPAddress], { p: [p1], b: hide }],
        [["Blocked Leads", "/blocked-lead-list", BlockListTable], { p: [p1] }],
        [["Add Blocked Lead", "/blocked-lead/add", BlockListForm], { p: [p1] }],
        [["Edit Blocked Lead", "/blocked-lead/edit/:id", BlockListForm], { p: [p1], b: hide }],
    ],
    Manual_Transactions: [
        [["Manual Transactions", "/manual-transactions", ManualTransactionList]],
        [["Add Manual Transaction", "/manual-transaction/add", ManualTransactionAdd]],
        [["Copy", "/manual-transaction/copy/:id", ManualTransactionAdd], { b: hide, pr: { copy: true } }],
        [["Fake Ftd Form", "/fake-ftd-form", FakeFtdForm], { p: [p1, p2] }],
        [["Add Simple Cost", "/simple-cost", SimpleCostForm]],
        [["Copy", "/simple-cost/copy/:id", SimpleCostForm], { b: hide, pr: { copy: true } }],
    ],
    /*Seo_Costs: [
        [["Seo Cost Form", "/seo-cost", SeoCostForm], { p: [p1, p3, p4] }],
        [["Seo Cost CSV Upload", "/seo-cost/csv-upload", SeoCostUpload], { p: [p1, p3, p4] }],
        [["Seo Cost List", "/seo-cost-list", SeoCostList], { p: [p1, p3] }],
    ],*/
    Gambling_Stats: [
        [["Gambling Stats Form", "/gambling-stats-add", GamblingStatsForm]],
        [["Gambling Stats CSV Upload", "/gambling-stats/csv-upload", GamblingStatsUpload]],
        [["Gambling Stats List", "/gambling-stats-list", GamblingStatsList]],
    ],
    Banner_Global_Rules: [
        [["Banner Global Rules Form", "/banner-global-rules/add", BannerGlobalRulesForm]],
        [["Banner Global Rules Form", "/banner-global-rules/edit/:id", BannerGlobalRulesForm], { b: hide }],
        [["Banner Global Rules List", "/banner-global-rules", BannerGlobalRulesList]],
    ],
    Tableau: [[["Trends By Partner Type", "/tableau-trends-by-partner-type", TableauTrendsByPartnerType]]],
    Tech_Domains: [
        [["Spider Domains", "/spider-domains", RedirectDomainList]],
        [["Prelander Domains", "/prelander-domains", PrelanderDomainsList]],
    ],
    Admin_Tools: [
        [["Clickout Accounts List", "/accounts", AccountsList]],
        [["Clickout Account Form", "/accounts/add", AccountsForm]],
        [["Clickout Account Form", "/accounts/edit/:id", AccountsForm], { b: hide }],
        [["Dashfx Users", "/users", UserV2]],
        [["Edit Dashfx User", "/users/:id", UserV2], { b: hide }],
        [["Dashfx User Form", "/manual-users/add", ManualUserCreateForm]],
        [["Global Rules", "/global-rules", GlobalRules]],
        [["Whitelist", "/whitelist", Whitelist]],
        [["Bad IP", "/partners/badip", WaterfallBadIp]],
        [["Global Deal Form", "/partners/global-deal-form", GlobalDealForm]],
        [["Default Partner Override", "/partner-override", DefaultIntO]],
        [["Status Mapping Override", "/status-mapping-override", StatusMappingOverride]],
        [["Unassigned Statuses Form", "/unassigned-status-form", UnassignedStatusForm]],
        [["Tag Management", "/tag-management", TagManagement]],
    ],
};

const isAllowedToSee = ({ user, permArr = [], path }) => {
    const { fePermissions } = user || {};
    const { grade = defaultUserTitle, included = [], excluded = [] } = fePermissions;

    const hasGradePermission = grade === "Admin" || permArr.includes(grade);
    const isIncludedPath = included?.includes(path) || (included?.includes("/seo-view") && path === "/");
    const isExcludedPath = excluded?.includes(path) || (excluded?.includes("/seo-view") && path === "/");

    return (hasGradePermission || isIncludedPath) && !isExcludedPath;
};

export const routeAdaptor = (route) => {
    const [mandatoryArr, optionalsObj] = route || [];
    const [name, path, component] = mandatoryArr || [];
    const { p: permArr = [], m: menuArr = [], b: behaviours, pr: addProps } = optionalsObj || {};
    return { name, path, component, permArr, menuArr, behaviours, addProps };
};

export const generateAllowedRoutes = (user) => {
    const isOperations = user?.fePermissions?.grade === "Operations" || false;
    const [allowedRoutes, listMenu, viewMenu, addMenu, tempMenu] = [[], {}, [], [], []];
    const allRoutes = [];

    _.forOwn(routes, (parentArr, parentName) => {
        listMenu[parentName] = [];

        _.forEach(parentArr, (route) => {
            const { name, path, component, permArr, menuArr, behaviours, addProps } = routeAdaptor(route);

            const routeForAll = {
                path,
                name,
                component,
                parentName,
                ...(addProps && { addProps }),
                ...(behaviours && { behaviours }),
            };
            allRoutes.push(routeForAll);

            const isAllowed = isAllowedToSee({ user, permArr, path });
            if (!isAllowed) return;

            const routeForAllowed = {
                path,
                name,
                component,
                parentName,
                ...(addProps && { addProps }),
                ...(behaviours && { behaviours }),
            };
            allowedRoutes.push(routeForAllowed);

            const isHidden = behaviours?.hide || parentName === "hiddenFromSidebar";
            if (isHidden) return;

            listMenu[parentName].push(routeForAllowed);
            menuArr.includes("view") && parentName !== "Temporary_Reports" && viewMenu.push(routeForAllowed);
            parentName === "Temporary_Reports" && tempMenu.push(routeForAllowed);
            menuArr.includes("add") && addMenu.push(routeForAllowed);
        });
    });

    if (isOperations) {
        let campaignBoxesComponent = allowedRoutes.find((r) => r.name === "Campaign Boxes").component;
        allowedRoutes.find((r) => r.name === "Home").component = campaignBoxesComponent;
    }

    return { allowedRoutes, listMenu, viewMenu, addMenu, tempMenu, allRoutes };
};
