import { useContext, AppContext, Icon, IconLib } from "V3";
import { S } from "./_styled";

export const MobileModal = ({ isOpen, onClose, children, modalTitle, enablePadding }) => {
    const { isDarkMode, isMobile } = useContext(AppContext);

    /* Return */

    if (!isMobile) return children;
    return (
        <S.Container isOpen={isOpen} isDarkMode={isDarkMode}>
            <S.ScrollArea enablePadding={enablePadding} isDarkMode={isDarkMode}>
                <div id="header">
                    <div id="modalTitle">{modalTitle}</div>
                    <div id="closeButton" onClick={onClose}>
                        <Icon icon={IconLib.IconClose} width={24} />
                    </div>
                </div>
                <div id="content">{children}</div>
            </S.ScrollArea>
        </S.Container>
    );
};
