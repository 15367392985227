import { styled, g, css } from "V3/Components/styledMixins/styledMixins.js";

export const Container = styled.div`
    color: ${(props) => (props.isDarkMode ? "#fff" : "#0E0E16")};
    cursor: ${(props) => (props.onClick ? "pointer" : "default")};
    display: ${(props) => (props.block ? "block" : "inline")};
    margin-top: ${(props) => (props.marginTop ? "30px" : 0)};
    font-size: 24px !important;
    font-weight: 300;
    user-select: none;
    margin-bottom: 10px;

    ${g.media(
        "phone",
        css`
            font-size: 14px;
            font-weight: 400;
        `,
    )}
`;
