//  background-color: ${(p) => (p.test2Color ? p.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};
//import { isDark } from "V3";

import { styled, css } from "V3/Components/styledMixins/styledMixins.js";

export const S = {
    Container: styled.div`
        ${({ isOpen, isDarkMode }) => css`
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            background-color: ${isDarkMode ? "#333333" : "#999999"};
            z-index: 100;
            transition: left 0.15s ease-in-out;
            left: ${isOpen ? 0 : "100%"};
            overflow-y: scroll;
        `}
    `,
    ScrollArea: styled.div`
        ${({ isDarkMode, enablePadding }) => css`
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;

            -webkit-overflow-scrolling: touch;

            & > #header {
                position: relative;
                top: 0;
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                width: 100%;
                height: 100px;
                padding: 20px;
                background-color: ${isDarkMode ? "rgba(0,0,0,0.4)" : "rgba(255,255,255,0.4)"};

                & > #modalTitle {
                    flex: 1 1 auto;
                    font-size: 22px;
                    font-weight: 100;
                    padding-left: 10px;
                }
                & > #closeButton {
                    flex: 0 0 50px;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            & > #content {
                width: 100%;
                padding: ${enablePadding ? "20px" : "0px"};
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        `}
    `,
};
