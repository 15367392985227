import { useRef, useEffect } from "V3";
import { useLocation } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ErrorIcon from "@material-ui/icons/Error";
import "./Snackbar.scss";

export const Snackbar = ({ snackBarState, handleClose, ...props }) => {
    const { open = false, message = "msg", type = "normal", persistent = false } = snackBarState || {};
    const timeoutRef = useRef();
    const location = useLocation();

    /**
     * Auto Close Timeout
     */
    useEffect(() => {
        clearTimeout(timeoutRef.current);
        if (open && !persistent) {
            timeoutRef.current = setTimeout(() => handleClose(), 10000);
        }
    }, [open, type, message, persistent]);

    /**
     * Timeout Cleanup
     */
    useEffect(
        () => () => {
            clearTimeout(timeoutRef.current);
        },
        [],
    );

    useEffect(() => {
        if (open && !persistent) {
            handleClose();
        }
    }, [location]);

    return (
        <div
            className={"Snackbar_container" + (open ? " on" : "")}
            style={{
                backgroundColor: type === "error" ? "darkred" : type === "success" ? "darkgreen" : "#666",
                color: "white",
            }}
        >
            <div className={"Snackbar_inner" + (open ? " on" : "")}>
                <div className={"Snackbar_msgIcon" + (open ? " on" : "")}>
                    {type === "error" ? <ErrorIcon /> : type === "success" ? <CheckIcon /> : <NotificationsIcon />}
                </div>
                <div className={"Snackbar_msg" + (open ? " on" : "")}> {message}</div>
                <div className={"Snackbar_closeIcon" + (open ? " on" : "")}>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </div>
            </div>
        </div>
    );
};
