import { useRef, useEffect, Button, useState, typeOfAdvanced } from "V3";
import MuiAutoComplete, { createFilterOptions } from "@material-ui/core/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { _ } from "core-js";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    tag: {
        maxWidth: "100%",
        height: "25px",
        margin: theme.spacing(0.2),
    },
    tagContainer: {
        // maxHeight: '80px',
        overflow: "auto",
        display: "flex",
        flexWrap: "wrap",
        gap: theme.spacing(0.2),
    },
    highlightedTag: {
        backgroundColor: "#FFA500",
    },
}));

export const V3AutoComplete = ({
    name,
    value,
    options = [],
    isGroupedOptions = false,
    layoutAssets,
    onChange,
    fullWidth = true,
    variant = "outlined",
    size = "small",
    required = false,
    label = "labelValue",
    error = false,
    useXstate = false,
    filterOptions,
    filterSelectedOptions = false,
    renderOption,
    onInputChange,
    startingDefault,
    renderInput,
    confirmationOnSelectionChange,
    confirmationOnPubHasAccount = false,
    open,
    onOpen,
    onClose,
    disabled,
    disableClearable = false,
    getOptionLabel = (item) => item,
    getOptionValue,
    isOptionEqualToValue = (option, value) => option === value,
    highlight = false,
    ...props
}) => {
    const classes = useStyles();

    const [isDialogOpen, setIsDialogOpen] = useState();
    const selectionValue = value?.value || (props.multiple ? [] : null);
    const selectionRef = useRef(selectionValue);
    const inputValue = value?.inputValue || "";
    const inputRef = useRef(inputValue);
    const lastApproved = useRef();
    const [selectedOptions, setSelectedOptions] = useState([]);

    const updateSelection = (val, reason) => onChange(val, name + ".value", reason);
    const updateInput = (val, reason) => onChange(val, name + ".inputValue", reason);

    const updateValues = (props) => {
        const { selection, input, reason } = props || {};
        if (selection !== undefined) {
            selectionRef.current = selection;
            onChange({ value: selection, inputValue: inputRef.current }, name, reason);
        }
        if (input !== undefined) {
            inputRef.current = input;
            onChange({ value: selectionRef.current, inputValue: input }, name, reason);
        }
    };

    useEffect(() => {
        selectionRef.current = selectionValue;
    }, [selectionValue]);

    const isGroupSelected = (groupTitle) => {
        const groupOptions = options[groupTitle] || [];
        return groupOptions.every((option) => selectedOptions.some((selected) => selected.id === option.id));
    };

    const GroupHeader = ({ groupTitle, onCheckboxClick, isGroupSelected }) => (
        <div style={{ display: "flex", alignItems: "center", cursor: "pointer", width: "100%" }}>
            <Chip
                label={groupTitle}
                onClick={() => onCheckboxClick(groupTitle)}
                icon={
                    <Checkbox
                        checked={isGroupSelected(groupTitle)}
                        onClick={(e) => {
                            e.stopPropagation();
                            onCheckboxClick(groupTitle);
                        }}
                    />
                }
                style={{ width: "100%", justifyContent: "start" }}
            />
        </div>
    );

    const handleCheckboxClick = (groupTitle) => {
        const groupOptions = options[groupTitle] || [];
        let newSelectedOptions;

        if (isGroupSelected(groupTitle)) {
            newSelectedOptions = selectedOptions.filter(
                (selected) => !groupOptions.some((option) => option.id === selected.id),
            );
        } else {
            newSelectedOptions = [
                ...selectedOptions,
                ...groupOptions.filter((option) => !selectedOptions.some((selected) => selected.id === option.id)),
            ];
        }

        setSelectedOptions(newSelectedOptions);
        onChange(newSelectedOptions, name + ".value", "group-change");
    };

    const renderGroup = (params) => [
        <li key={params.key}>
            <GroupHeader
                groupTitle={params.group}
                onCheckboxClick={handleCheckboxClick}
                isGroupSelected={isGroupSelected}
            />
        </li>,
        params.children,
    ];

    /**
     * onChange
     */
    const onSelectionChange = (e, newValue, reason, option, passConfirmation) => {
        if (isGroupedOptions) {
            setSelectedOptions(newValue);
        }

        if (_.isEqual(newValue, selectionValue)) return;

        if (
            confirmationOnPubHasAccount &&
            (!newValue || newValue?.length < value?.value?.length || !newValue[newValue?.length - 1]?.hasAccount)
        ) {
            confirmationOnSelectionChange = false;
        }

        if (confirmationOnSelectionChange && !passConfirmation && selectionValue) {
            setIsDialogOpen({ value: newValue });
        } else {
            lastApproved.current = newValue;
            if (useXstate) updateSelection(newValue, reason);
            else {
                selectionRef.current = newValue;
                updateValues({ selection: newValue, reason });
            }
        }
    };
    const onInputInternalChange = (e, newInputValue, reason) => {
        if (e === null && reason === "reset" && !newInputValue) return; // fix for double input required for multiple autocomplete
        if (onInputChange) onInputChange(e, newInputValue, reason);
        if (useXstate) updateInput(newInputValue, reason);
        else {
            inputRef.current = newInputValue;
            updateValues({ input: newInputValue, reason });
        }
    };

    /**
     * Approve - Cancel for Prompt
     */
    const cancelConfirmation = () => {
        const lastConfirmed = lastApproved?.current;
        onSelectionChange({}, lastConfirmed, "approved", {}, true);
        onInputInternalChange({}, lastConfirmed && getOptionLabel(lastConfirmed), "input_after_cancel");
        setIsDialogOpen();
    };
    const approveConfirmation = () => {
        onSelectionChange({}, isDialogOpen?.value, "approved", {}, true);
        onInputInternalChange({}, isDialogOpen?.value ? getOptionLabel(isDialogOpen.value) : "", "input_after_approve");
        setIsDialogOpen();
    };

    /**
     * update value if there is a startingDefault and current value is empty, onLoad
     */

    const updateValue = () => {
        if (!startingDefault || value?.value) return;
        onSelectionChange(null, startingDefault, "startingDefault");
        onInputInternalChange(null, "", "startingDefault");
    };
    useEffect(updateValue, [startingDefault]);

    /**
     * load with keys
     */
    const loadByKeys = () => {
        if (!value || typeOfAdvanced(value) === "object") return;
        const findOptionById = _.find(options, (o) => {
            const keys = Object.keys(o);
            let response = false;
            _.forEach(keys, (key) => {
                if (o[key] === value) {
                    response = true;
                    return false;
                }
            });
            return response;
        });
        if (!findOptionById) return;
        onSelectionChange(null, findOptionById, "startingDefault");
        onInputInternalChange(null, "", "startingDefault");
    };
    useEffect(loadByKeys, []);

    /**
     * Default Filter Options
     */

    const maxLimit = 50;
    const defaultFilterOptions = createFilterOptions();
    const innerFilterOptions = (options, state) => defaultFilterOptions(options, state).slice(0, maxLimit);

    const preprocessOptions = (options) => {
        const usedCategories = {};
        const preprocessedOptions = [];

        Object.keys(options).forEach((group) => {
            if (!Array.isArray(options[group])) {
                return;
            }

            options[group].forEach((option) => {
                if (!usedCategories[option.id]) {
                    usedCategories[option.id] = new Set();
                }
                if (!usedCategories[option.id].has(group)) {
                    preprocessedOptions.push({ ...option, group });
                    usedCategories[option.id].add(group);
                }
            });
        });

        return preprocessedOptions;
    };

    let groupedOptionsWithAssignedCategories;

    if (isGroupedOptions) {
        groupedOptionsWithAssignedCategories = preprocessOptions(options);
    }

    const groupByFunction = (option) => {
        return option.group;
    };

    const renderTags = (value, getTagProps) => (
        <div className={classes.tagContainer}>
            {value.map((option, index) => (
                <Chip
                    label={getOptionLabel(option)}
                    {...getTagProps({ index })}
                    className={`${classes.tag} ${highlight === option?.name ? classes.highlightedTag : ""}`}
                />
            ))}
        </div>
    );

    /**
     * Update Value if value is not object and option is updated.
     */
    useEffect(() => {
        if (!value || typeof value === "object" || !getOptionValue) return;

        const opts = isGroupedOptions ? groupedOptionsWithAssignedCategories : options;
        if (!opts || opts.length < 1) return;

        const findItem = opts.find((item) => {
            const itemValue = getOptionValue?.(item);
            return itemValue === value;
        });
        onSelectionChange(null, findItem, "Metadata update.");
    }, [isGroupedOptions ? groupedOptionsWithAssignedCategories : options, value]);

    /**
     * Return
     */
    return (
        <>
            <MuiAutoComplete
                value={isGroupedOptions ? selectedOptions : selectionValue}
                disabled={disabled}
                onChange={onSelectionChange}
                inputValue={inputValue}
                renderTags={renderTags}
                onInputChange={onInputInternalChange}
                options={isGroupedOptions ? groupedOptionsWithAssignedCategories : options}
                groupBy={isGroupedOptions ? groupByFunction : undefined}
                getOptionLabel={getOptionLabel}
                disableClearable={disableClearable}
                isOptionEqualToValue={isOptionEqualToValue}
                filterSelectedOptions={filterSelectedOptions}
                // {...(filterOptions && { filterOptions: filterOptions })}
                filterOptions={filterOptions || innerFilterOptions}
                renderOption={
                    renderOption ||
                    ((props, option, index) => {
                        return (
                            <li {...props} key={option.id || props["data-option-index"] || getOptionLabel(option)}>
                                {getOptionLabel(option)}
                            </li>
                        );
                    })
                }
                renderGroup={isGroupedOptions ? renderGroup : undefined}
                fullWidth={fullWidth}
                variant={variant}
                size={size}
                //
                blurOnSelect={props.multiple ? false : true}
                renderInput={
                    renderInput
                        ? renderInput
                        : (params) => (
                              <TextField
                                  {...params}
                                  required={required}
                                  label={label}
                                  variant="outlined"
                                  error={error}
                                  autoComplete="new"
                                  disabled={disabled || false}
                              />
                          )
                }
                //
                open={open}
                onOpen={onOpen}
                onClose={onClose}
                {...props}
            />
            {confirmationOnSelectionChange && (
                <Dialog
                    open={isDialogOpen}
                    onClose={() => setIsDialogOpen()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    onBackdropClick={cancelConfirmation}
                >
                    <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {confirmationOnPubHasAccount ? (
                                <>
                                    {" "}
                                    <p>
                                        This publisher{" "}
                                        {isDialogOpen?.value?.[isDialogOpen?.value?.length - 1]?.name || ""} is already
                                        added elsewhere. Do you want to continue?{" "}
                                    </p>{" "}
                                    <p style={{ fontSize: "0.9rem" }}>
                                        {" "}
                                        <i>
                                            {" "}
                                            To find which account is linked to this publisher, please visit our{" "}
                                            <a
                                                href="#/accounts"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ color: "#7267d3" }}
                                            >
                                                Accounts List
                                            </a>{" "}
                                            and search for this publisher name. This will display the relevant account
                                            details.{" "}
                                        </i>{" "}
                                    </p>
                                </>
                            ) : (
                                confirmationOnSelectionChange
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={cancelConfirmation}> {confirmationOnPubHasAccount ? "No" : "Cancel"}</Button>
                        <Button onClick={approveConfirmation} variant="contained" color="error">
                            {confirmationOnPubHasAccount ? "Yes" : "Approve"}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};
