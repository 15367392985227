import dayjs from "config/dayjs-setup";

export const AG_GRID_LICENSE_KEY =
    "CompanyName=Finixio,LicensedApplication=DashFX,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-024783,ExpiryDate=22_March_2023_[v2]_MTY3OTQ0MzIwMDAwMA==8232b184263ca5869160d4e8193f977b";
export const dayjsFormat = "YYYY-MM-DD";
export const dateRangeOptions = [
    { label: "Specify Day", value: "one_day" },
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last 7 Days", value: "7" },
    { label: "Month to Date", value: "month" },
    { label: dayjs().subtract(1, "months").format("MMM-YYYY"), value: "fullmonth" },
    { label: "Last 30 Days", value: "30" },
    { label: "Last 60 Days", value: "60" },
    { label: "Last 90 Days", value: "90" },
    { label: "Last 180 Days", value: "180" },
    { label: "Last 365 Days", value: "365" },
    { label: "Custom", value: "custom" },
];
const TODAY = dayjs();
const TODAYSTR = TODAY.format(dayjsFormat);
const YESTERDAY = dayjs().subtract(1, "days");
const YESTERDAYSTR = YESTERDAY.format(dayjsFormat);
const LASTWEEK = dayjs().subtract(7, "days");
const THISMONTH = dayjs().startOf("month");
const PREMONTHSTART = dayjs().subtract(1, "months").startOf("month");
const PREMONTHEND = dayjs().subtract(1, "months").endOf("month");
const LAST30 = dayjs().subtract(30, "days");
const LAST60 = dayjs().subtract(60, "days");
const LAST90 = dayjs().subtract(90, "days");
const LAST180 = dayjs().subtract(180, "days");
const LAST365 = dayjs().subtract(365, "days");

export const dateRangeArr = {
    one_day: { str: [TODAYSTR, TODAYSTR], dayjss: [TODAY, TODAY] },
    today: { str: [TODAYSTR, TODAYSTR], dayjss: [TODAY, TODAY] },
    yesterday: { str: [YESTERDAYSTR, YESTERDAYSTR], dayjss: [YESTERDAY, YESTERDAY] },
    7: { str: [LASTWEEK.format(dayjsFormat), TODAYSTR], dayjss: [LASTWEEK, TODAY] },
    month: { str: [THISMONTH.format(dayjsFormat), TODAYSTR], dayjss: [THISMONTH, TODAY] },
    fullmonth: {
        str: [PREMONTHSTART.format(dayjsFormat), PREMONTHEND.format(dayjsFormat)],
        dayjss: [PREMONTHSTART, PREMONTHEND],
    },
    30: { str: [LAST30.format(dayjsFormat), TODAYSTR], dayjss: [LAST30, TODAY] },
    60: { str: [LAST60.format(dayjsFormat), TODAYSTR], dayjss: [LAST60, TODAY] },
    90: { str: [LAST90.format(dayjsFormat), TODAYSTR], dayjss: [LAST90, TODAY] },
    180: { str: [LAST180.format(dayjsFormat), TODAYSTR], dayjss: [LAST180, TODAY] },
    365: { str: [LAST365.format(dayjsFormat), TODAYSTR], dayjss: [LAST365, TODAY] },
};
export const findDates = (method, key) => {
    if (!key || !dateRangeArr?.[key]) return null;

    if (method === "moments") {
        // Convert stored values to Dayjs objects
        return [dayjs(dateRangeArr[key].startDate), dayjs(dateRangeArr[key].endDate)];
    }

    return dateRangeArr?.[key][method || "str"];
};

export const manageDate = (newValue, setState) => {
    if (!newValue) return;

    let span = newValue.value;
    if (span === "custom" || span === "one_day") return;

    let [startDate, endDate] = findDates("moments", span);

    if (!startDate || !endDate) return; // Ensure valid values exist

    // Convert dates to ISO strings to match `dateFilters` structure
    const formattedDates = [startDate.toISOString(), endDate.toISOString()];

    if (setState) {
        setState({ dates: formattedDates });
        return;
    } else {
        return { dates: formattedDates };
    }
};

//HTTP RESPONSES
export const UNAUTHORIZED = "UNAUTHORIZED";
export const ITEM_NOT_FOUND = "ITEM_NOT_FOUND";
export const SERVER_ERROR = "SERVER_ERROR";
export const SUCCESS = "SUCCESS";
export const FAIL = "FAIL";
export const FORBIDDEN = "FORBIDDEN";
export const METHOD_NOT_ALLOWED = "METHOD_NOT_ALLOWED";
export const NETWORK_ERROR = "NETWORK_ERROR";

// ERROR MESSAGES
export const unExpectedErrorMessage = "Unexpected error occurred";
export const serverErrorMessage = "Server Error.";
export const itemNotFoundErrorMessage = "Item not found";
export const unauthorizedErrorMessage =
    "Unauthorized Request. Please re-login. You can sign out using the button in the upper right corner.";
export const forbiddenRequestErrorMessage = "Forbidden Request";
export const failErrorMessage = "Process failed";
export const networkErrorMessage = "Network error. Please try again.";
export const generalSuccessMessage = "Process successfully completed";

//VALIDATION MESSAGES
export const requiredField = "Required field";
