import { metadataConstants } from "../constants/metadata";
import headers from "../../config/headers";
import axios from "axios";
import { idLabelNameConvertor as con1 } from "./idLabelNameConvertor.js";

export const metadata = {
    getMetaData,
    refreshMetaData,
};

function getMetaData(queryArgs = null) {
    return async (dispatch, getState) => {
        const state = getState();
        const baseUrl = process.env.REACT_APP_API_URL + "metadata";

        // 1) No args => fetch entire metadata
        if (!queryArgs) {
            const { data: response } = await axios.get(baseUrl, headers());
            const corrected = metadataGlobalCorrections(response);
            dispatch(success(corrected));
            return corrected?.data; // entire corrected payload
        }

        // 2) Single property => old single-property logic
        if (typeof queryArgs === "string") {
            return await fetchSingleProperty(queryArgs, dispatch, state, baseUrl);
        }

        // 3) Array of properties => new multi-property logic
        if (Array.isArray(queryArgs)) {
            const currentData = state.metadata?.data || {};
            const currentHashes = state.metadata?.hashes || {};

            // A) Check if all requested properties are missing
            let allAreMissing = true;
            for (let propertyName of queryArgs) {
                if (currentData[propertyName]) {
                    allAreMissing = false;
                    break;
                }
            }

            const results = {};

            // B) If *all* are missing, skip /hashes
            if (allAreMissing) {
                const promises = queryArgs.map((propertyName) =>
                    axios.get(`${baseUrl}/${propertyName}`, headers()).then(({ data: response }) => {
                        dispatch(updateMetadataProperty(propertyName, response));
                        return { propertyName, value: response?.data?.[propertyName] };
                    }),
                );

                const settledResults = await Promise.allSettled(promises);

                settledResults.forEach((result, idx) => {
                    const propertyName = queryArgs[idx];
                    if (result.status === "fulfilled") {
                        results[propertyName] = result.value.value;
                    } else {
                        console.error(`Failed to fetch property ${propertyName}`, result.reason);
                        results[propertyName] = null;
                    }
                });

                // Return results in the same order as queryArgs
                return queryArgs.map((prop) => results[prop]);
            }

            // C) If some property is already in state, fetch /hashes once to check for changes
            let hashResponse;
            try {
                const { data } = await axios.get(`${baseUrl}/hashes`, headers());
                hashResponse = data?.data || {};
            } catch (error) {
                console.error("Could not fetch metadata/hashes: ", error);
                // Fallback: if we can't fetch hashes, re-fetch everything requested
            }

            const missingOrOutdatedProps = [];
            for (let propertyName of queryArgs) {
                const isInStore = !!currentData[propertyName];
                const oldHash = currentHashes[propertyName];
                const newHash = hashResponse ? hashResponse[propertyName] : null;

                // If missing or changed, we add to the fetch list
                if (!isInStore || (newHash && newHash !== oldHash)) {
                    missingOrOutdatedProps.push(propertyName);
                } else {
                    // If not missing or changed, just use what's in the store
                    results[propertyName] = currentData[propertyName];
                }
            }

            // D) Fetch missing or outdated
            if (missingOrOutdatedProps.length > 0) {
                for (let prop of missingOrOutdatedProps) {
                    try {
                        const { data: response } = await axios.get(`${baseUrl}/${prop}`, headers());
                        dispatch(updateMetadataProperty(prop, response));
                        results[prop] = response?.data?.[prop];
                    } catch (err) {
                        console.error(`Failed to fetch property ${prop}`, err);
                        results[prop] = null;
                    }
                }
            }

            // E) Return array results in the same order
            return queryArgs.map((prop) => results[prop]);
        }
    };

    function success(data) {
        return {
            type: metadataConstants.GET_METADATA_SUCCESS,
            data,
        };
    }

    function updateMetadataProperty(key, data) {
        return {
            type: metadataConstants.UPDATE_METADATA_PROPERTY,
            key,
            data,
        };
    }
}

// OLD LOGIC, extracted as helper
async function fetchSingleProperty(queryArgs, dispatch, state, baseUrl) {
    const currentData = state.metadata?.data || {};
    const currentHashes = state.metadata?.hashes || {};

    if (!currentData[queryArgs]) {
        // Not in state => fetch immediately
        const { data: response } = await axios.get(`${baseUrl}/${queryArgs}`, headers());
        dispatch(updateMetadataProperty(queryArgs, response));
        return response?.data?.[queryArgs];
    } else {
        // Already in state => check hash
        const { data: hashResponse } = await axios.get(`${baseUrl}/hashes`, headers());
        const newHash = hashResponse?.data?.[queryArgs];
        const oldHash = currentHashes[queryArgs];

        if (newHash && newHash !== oldHash) {
            // Hash changed => refetch
            const { data: response } = await axios.get(`${baseUrl}/${queryArgs}`, headers());
            dispatch(updateMetadataProperty(queryArgs, response));
            return response?.data?.[queryArgs];
        } else {
            // Hash same => skip
            return currentData[queryArgs];
        }
    }

    function updateMetadataProperty(key, data) {
        return {
            type: metadataConstants.UPDATE_METADATA_PROPERTY,
            key,
            data,
        };
    }
}

function refreshMetaData(queryArgs = null) {
    return (dispatch, getState) => {
        let baseUrl = process.env.REACT_APP_API_URL + "metadata";
        let getUrl = queryArgs ? `${baseUrl}/${queryArgs}/refresh` : `${baseUrl}/refresh`;

        axios.get(getUrl, headers()).then(
            (result) => {
                if (queryArgs) {
                    dispatch(updateMetadataProperty(queryArgs, metadataGlobalCorrections(result.data)));
                } else {
                    dispatch(success(metadataGlobalCorrections(result.data)));
                }
                console.log("state metadata refreshed.", queryArgs);
            },
            (error) => {
                console.log(error);
            },
        );
    };

    function success(data) {
        return {
            type: metadataConstants.GET_METADATA_SUCCESS,
            data,
        };
    }

    function updateMetadataProperty(key, data) {
        return {
            type: metadataConstants.UPDATE_METADATA_PROPERTY,
            key,
            data,
        };
    }
}

export const metadataGlobalCorrections = (res) => {
    if (res?.data?.commonOfferLanderCampaigns) {
        res.data.commonOfferLanderCampaigns = con1(res.data.commonOfferLanderCampaigns);
    }
    if (res?.data?.offerLanderCampaigns) {
        res.data.offerLanderCampaigns = con1(res.data.offerLanderCampaigns);
    }
    if (res?.data?.preLanderCampaigns) {
        res.data.preLanderCampaigns = con1(res.data.preLanderCampaigns);
    }
    if (res?.data?.externalLanderCampaigns) {
        res.data.externalLanderCampaigns = con1(res.data.externalLanderCampaigns);
    }
    if (res?.data?.externalLanderCampaignData) {
        res.data.externalLanderCampaignData = con1(res.data.externalLanderCampaignData);
    }
    if (res?.data?.publishers) {
        res.data.publishers = con1(res.data.publishers);
    }

    return res;
};
