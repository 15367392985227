import { useContext, AppContext, Icon } from "V3";
import { S } from "./_styled";

// icon - iconSize - label - url - onClick - iconColor
export const MobileButtons = ({ actions = [], enablePadding }) => {
    const { isMobile } = useContext(AppContext);

    /* Return */
    if (!isMobile) return null;
    return (
        <S.Container enablePadding={enablePadding}>
            {actions.map((item, index) => (
                <SingleButton key={index} {...item} />
            ))}
        </S.Container>
    );
};

const SingleButton = ({ label, icon, iconSize, iconColor, onClick, url, disabled }) => {
    const { isDarkMode } = useContext(AppContext);

    /* Return */
    if (disabled) return null;
    return (
        <S.SingleButton {...{ isDarkMode }} onClick={onClick}>
            {icon && (
                <div id="iconArea">
                    <Icon icon={icon} width={iconSize || 16} color={iconColor || (isDarkMode ? "white" : "black")} />
                </div>
            )}
            <div id="label">{label}</div>
        </S.SingleButton>
    );
};
